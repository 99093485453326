import {FormControllerAPI} from '@wix/form-viewer';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {CustomFieldModel} from '../models/CustomField.model';
import {
  CashierMandatoryFieldsOverrides,
  CustomerDetailsFormSection,
  FieldPropertiesByTarget,
} from '../../types/app.types';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {
  FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER,
  FORMS_TEMPLATE_IDS,
  SPECS,
} from '../../components/Checkout/constants';
import {
  getContactFormInitialState,
  getContactFormOverrides,
} from '../../components/Checkout/Form/ContactForm/contactForm.utils';
import {
  getAddressFormInitialState,
  getAddressFormOverrides,
} from '../../components/Checkout/Form/AddressForm/addressForm.utils';
import {
  getAdditionalInfoFormInitialState,
  getAdditionalInfoFormOverrides,
} from '../../components/Checkout/Form/AdditionalInfoForm/AdditionalInfoForm.utils';
import {getVatFormInitialState} from '../../components/Checkout/Form/VatIdForm/VatForm.utils';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {getExtendedFieldsFormInitialState} from '../../components/Checkout/Form/ExtendedFieldsForm/ExtendedFieldsForm.utils';
import {ExtendedFieldsFragment} from '../../gql/graphql';
import {isCountryWithVat} from '../utils/isCountryWithVat';
import {
  getBillingFormInitialState,
  getBillingFormOverrides,
} from '../../components/Checkout/Form/BillingForm/BillingForm.utils';
import {CheckoutSettingsService} from './CheckoutSettingsService';
import {CountryCode, FormValues} from '@wix/form-fields';
import {FormOverrides} from '@wix/form-viewer/dist/types/services/form-overrides';
import {
  getCustomerDetailsFormInitialState,
  getCustomerDetailsFormOverrides,
} from '../../components/Checkout/Form/CustomerDetailsForm/CustomerDetailsForm.utils';
import {MemberService} from './MemberService';

export class FormsService {
  public validateForm: FormControllerAPI['validateForm'];
  private validationFunctionResolve!: (validateForm: FormControllerAPI['validateForm']) => void;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly memberService: MemberService;
  public isExtendedFieldsFormValid?: boolean;
  public shippingDetailsFieldsProps: FieldPropertiesByTarget | null = null;

  constructor({
    siteStore,
    checkoutSettingsService,
    memberService,
  }: {
    siteStore: SiteStore;
    checkoutSettingsService: CheckoutSettingsService;
    memberService: MemberService;
  }) {
    const validationFunctionPromise = new Promise<FormControllerAPI['validateForm']>((resolve) => {
      this.validationFunctionResolve = resolve;
    });

    this.validateForm = async (...args) => {
      const validate = await validationFunctionPromise;
      return validate(...args);
    };

    this.siteStore = siteStore;
    this.checkoutSettingsService = checkoutSettingsService;
    this.memberService = memberService;
  }

  public setFormValidator = (validateForm: FormControllerAPI['validateForm']) => {
    this.validationFunctionResolve(validateForm);
  };

  /* istanbul ignore next */
  public setShippingDetailsFieldsProps = (properties: FieldPropertiesByTarget | null | undefined = null) => {
    this.shippingDetailsFieldsProps = properties;
  };

  public setExtendedFieldsFormValid = async (extendedFields?: ExtendedFieldsFragment | null) => {
    const extendedFieldsErrors = await this.validateForm({
      formId: FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID,
      values: getExtendedFieldsFormInitialState(extendedFields ?? undefined),
    });
    this.isExtendedFieldsFormValid = !extendedFieldsErrors?.errors.length;
  };

  public isAddressValidForBilling = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      cashierMandatoryFields,
      checkoutComposerEnabled,
    }: {
      checkoutSettings: CheckoutSettingsModel;
      cashierMandatoryFields: CashierMandatoryFieldsOverrides;
      checkoutComposerEnabled: boolean;
    }
  ): Promise<boolean> => {
    const useSingleBillingForm = this.siteStore.experiments.enabled(SPECS.UseSingleBillingForm);

    const billingFormValidator = () => {
      const overrides = getBillingFormOverrides({
        cashierMandatoryFields,
        checkoutSettings,
        checkoutComposerEnabled,
        shippingDetailsFieldsProps: this.shippingDetailsFieldsProps ?? /* istanbul ignore next */ {},
        isGroo: this.checkoutSettingsService.isGroo,
        excludeGoogleAutoComplete: this.siteStore.experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
        country: addressWithContact.address?.country as CountryCode,
      }) as FormOverrides;

      return this.validateForm({
        formId: FORMS_TEMPLATE_IDS.BILLING,
        values: getBillingFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          overridePhone: cashierMandatoryFields.phone,
          checkoutComposerEnabled,
          initialAddress: addressWithContact.address,
          billingContact: addressWithContact.contact,
        }),
        overrides,
      });
    };

    const contactValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.CONTACT
          : FORMS_TEMPLATE_IDS.CONTACT,
        values: getContactFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          overridePhone: cashierMandatoryFields?.phone,
          checkoutComposerEnabled,
        }),
        overrides: getContactFormOverrides({
          checkoutSettings,
          overridePhone: cashierMandatoryFields?.phone,
          checkoutComposerEnabled,
        }),
      });

    const addressValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS
          : FORMS_TEMPLATE_IDS.ADDRESS,
        values: getAddressFormInitialState(checkoutSettings, checkoutComposerEnabled, addressWithContact.address),
        overrides: getAddressFormOverrides({
          checkoutSettings,
          overrides: cashierMandatoryFields,
          checkoutComposerEnabled,
        }),
      });

    const vatValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.VAT_ID,
        values: getVatFormInitialState(addressWithContact.contact),
        overrides: {},
      });

    const shouldValidateVat = isCountryWithVat(addressWithContact.address?.country);

    return validateAllForms(
      useSingleBillingForm
        ? [billingFormValidator]
        : [contactValidator, addressValidator, ...(shouldValidateVat ? [vatValidator] : [])]
    );
  };

  public isAddressValidForShipping = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      customField,
      isShippingFlow,
      checkoutComposerEnabled,
    }: {
      checkoutSettings: CheckoutSettingsModel;
      customField?: CustomFieldModel;
      isShippingFlow: boolean;
      checkoutComposerEnabled: boolean;
    }
  ): Promise<boolean> => {
    const shouldFilterByShippingCountries = this.siteStore.experiments.enabled(
      SPECS.FilterCountriesByShippingCountries
    );

    const useConsolidatedCustomerDetailsForm =
      this.siteStore.experiments.enabled(SPECS.UseSingleCustomerDetailsForm) && !checkoutComposerEnabled;

    const customerDetailsValidator = () =>
      this.validateForm({
        formId: FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_ADDITIONAL_INFO,
        values: getCustomerDetailsFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          checkoutComposerEnabled,
          address: addressWithContact.address,
          isGroo: undefined,
          shouldFilterByShippingCountries,
          customField,
        }) as FormValues,
        overrides: getCustomerDetailsFormOverrides({
          checkoutSettings,
          isGroo: this.checkoutSettingsService.isGroo,
          excludeGoogleAutoComplete: this.siteStore.experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
          shouldFilterByShippingCountries,
          checkoutComposerEnabled,
          isShippingFlow,
          isMember: this.memberService.isMember(),
          formSections: [
            CustomerDetailsFormSection.contact,
            CustomerDetailsFormSection.address,
            CustomerDetailsFormSection.additionalInfo,
          ],
        }) as FormOverrides,
      });

    const contactValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.CONTACT
          : FORMS_TEMPLATE_IDS.CONTACT,
        values: getContactFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          country: addressWithContact.address?.country,
          checkoutComposerEnabled,
        }),
        overrides: getContactFormOverrides({checkoutSettings, checkoutComposerEnabled}),
      });

    const addressValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS
          : FORMS_TEMPLATE_IDS.ADDRESS,
        values: getAddressFormInitialState(
          checkoutSettings,
          checkoutComposerEnabled,
          addressWithContact.address,
          undefined,
          shouldFilterByShippingCountries
        ),
        overrides: getAddressFormOverrides({
          checkoutSettings,
          checkoutComposerEnabled,
          shouldFilterByShippingCountries,
        }),
      });

    const additionalInfoValidator = () =>
      this.validateForm({
        formId: checkoutComposerEnabled
          ? FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID
          : FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
        values: getAdditionalInfoFormInitialState(customField),
        overrides: getAdditionalInfoFormOverrides({checkoutSettings}),
      });

    return validateAllForms(
      useConsolidatedCustomerDetailsForm
        ? [customerDetailsValidator]
        : [
            contactValidator,
            ...(isShippingFlow ? [addressValidator] : /* istanbul ignore next */ []),
            additionalInfoValidator,
          ]
    );
  };
}

async function validateAllForms(formValidations: (() => Promise<{errors: any[]} | undefined> | undefined)[]) {
  const validationErrors = await Promise.all(formValidations.map((validateForm) => validateForm()));
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
}
